<template>
	<div class="text-center" style="position: relative">
		<div class="rounded-circle overflow-hidden fill-height elevation-2 ma-1" :style="{height: parent_width + 'px'}">
			<v-hover v-slot:default="{ hover }">
				<div class="fill-height">
					<v-img :min-width="180" :src="new_src !== null ? new_src : typeof new_val === 'string' ? backend_url + new_val : ''" height="100%" width="100%" class="cell">
						<div v-if="new_src === null && !new_val" class="text-center cell2 fill-height d-flex pa-3"
						     style="flex-direction: column; justify-content: center"
						>
							<v-icon color="cell4" size="40">mdi-image-off-outline</v-icon>
							<div class="cell4--text title-2 font-weight-bold">{{ $t('single_trad.ImagePlaceholder.no_image')}}</div>
						</div>
						<div
								v-else-if="new_src !== null"
								style="display: flex; flex-direction: row; align-items: flex-end; justify-content: center"
								class="fill-height pb-6"
						>
							<div class="pr-2">
								<Button color="celldark" dark iconVal="mdi-close" :click="() => removeImage()" :toolTip="$tc('global.action.cancel', 1)" />
							</div>
							<div>
								<Button color="primary" iconVal="mdi-check" :click="() => saveImage()" :toolTip="$tc('single_trad.ItemImage.save', 1)" :loading="loading" />
							</div>
						</div>
						<v-fade-transition>
							<v-overlay
									absolute
									v-if="hover && new_src === null"
									color="black"
									opacity="0.8"
							>
								<div v-if="!new_val && new_src === null" @click="browseFile()" class="sub-title-2 cursor-pointer text-center">
									<v-icon large>mdi-open-in-new</v-icon>
									<div>{{ $tc('single_trad.ImagePlaceholder.add_image', 1) }}</div>
								</div>
								<div v-else-if="new_val" class="sub-title-2 text-center cursor-pointer" @click="browseFile()">
									<v-icon large>mdi-pencil</v-icon>
									<div>{{ $tc('single_trad.ItemImage.edit', 1) }}</div>
								</div>
							</v-overlay>
						</v-fade-transition>
					</v-img>
				</div>
			</v-hover>
			<input
					type="file"
					accept="image/*"
					hidden
					ref="file_upload"
					@change="(e) => preloadPicture(e)"
			/>
		</div>
		<div style="position: absolute; bottom: 0; left: 0;" v-if="new_val && new_src === null">
			<Button iconVal="mdi-delete" color="error" :toolTip="$tc('single_trad.ItemImage.delete', 1)" :click="() => confirm_dialog = true" />
		</div>

		<Dialog v-model="confirm_dialog" type="confirm" :message="$tc('single_trad.ItemImage.dialog_v', 1)"
		        @cancel="confirm_dialog = false" @confirm="deleteImage()" :loading="loading"/>
	</div>
</template>

<script>
	export default {
		name: "IDMediaField",
		props: ['value', 'path', 'fieldName'],
		components: {
			Dialog: () => import('@/components/ui/Dialog.vue'),
			Button: () => import('@/components/ui/Button.vue')
		},
		data(){
			return {
				new_src: null,
				parent_width: 0,
				loading: false,
				confirm_dialog: false,
				old_val: null
			}
		},
		computed: {
			new_val: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			},
			backend_url() {
				return this.$store.getters['config/backend_url']
			}
		},
		methods: {
			browseFile() {
				this.$refs['file_upload'].click()
			},
			preloadPicture(e) {
				const maxSize = 2000000

				if (e.target.files.length) {
					//if (e.target.files[0].size < maxSize) {
					const files = e.target.files[0]
					this.old_val = this.new_val ? this.new_val : null
					this.new_val = files
					this.new_src = URL.createObjectURL(files)
					//}
				}
			},
			removeImage() {
				this.new_src = null
				this.new_val = this.old_val
			},
			deleteImage() {
				this.loading = true

				let data = {}

				data[this.fieldName] = null

				this.$wsc.patchItem(this.path, this.$route.params[this.$route.name], data, succ => {
					this.confirm_dialog = false
					this.new_src = succ[this.fieldName]
					this.new_val = succ[this.fieldName]
					this.loading = false
					this.$store.dispatch('snackbar/success', this.$t('snackbar.ItemImage.deleteImage.success'))
				}, fai => {
					this.loading = false
					this.$store.dispatch('snackbar/error', this.$t('snackbar.ItemImage.deleteImage.fail'))
				})
			},
			saveImage() {
				this.loading = true
				let data = {}

				data[this.fieldName] = this.new_val

				this.$wsc.toFormData(data, (success, header) => {
					this.$wsc.patchItem(this.path, this.$route.params[this.$route.name], success, succ => {
						this.new_src = null
						this.new_val = succ[this.fieldName]
						this.loading = false
						this.$store.dispatch('snackbar/success', this.$t('snackbar.Cart.addRemoveExt.success'))
					}, fai => {
						this.loading = false
						this.$store.dispatch('snackbar/error', this.$t('snackbar.ItemImage.addRemoveExt.fail'))
					}, header)
				})
			}
		},
		mounted() {
			this.parent_width = this.$el.clientWidth
		}
	}
</script>
